import React from 'react'
import { Segment } from 'components/segment/Segment';
import SEO from 'components/seo';

export default () => (
    <>
        <SEO title="Terms page" />
        <Segment>
            <div  style={{minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                This is Terms page..!
            </div>
        </Segment>
    </>
    )
